import API_STATUS_CODES from './responseStatusConstants';

const handleResponse = (response: any) => {
  return response.text().then(async (text: any) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status !== API_STATUS_CODES.NOT_FOUND) {
        const error =
          (data && data.message) || response.statusText || (data && data.error);
        return Promise.reject({status: response.status, message: error});
      }
    }
    return data;
  });
};

export default handleResponse;
