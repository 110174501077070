import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router';
import HomeView from '../views/AboutView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/experience',
    name: 'experience',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/ExperienceView.vue'),
  },
  {
    path: '/projects',
    name: 'projects',
    component: () => import('../views/ProjectsView.vue'),
  },
  {
    path: '/academic',
    name: 'academic',
    component: () => import('../views/AcademicView.vue'),
  },
  {
    path: '/leadership',
    name: 'leadership',
    component: () => import('../views/LeadershipView.vue'),
  },
  {
    path: '/teamwork',
    name: 'teamwork',
    component: () => import('../views/TeamworkView.vue'),
  },
  {
    path: '/resume',
    name: 'resume',
    component: () => import('../views/ResumeView.vue'),
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/ContactView.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
