import handleResponse from './apiResponseHandler';

export const HTTP_METHOD = {
  GET: 'Get',
  POST: 'Post',
  PUT: 'Put',
  DELETE: 'Delete',
} as const;
export type HttpMethodTypes = (typeof HTTP_METHOD)[keyof typeof HTTP_METHOD];

export const httpService = async (data: {
  method: HttpMethodTypes;
  url: string;
  body?: any;
}): Promise<any> => {
  const {method, url, body} = data;

  let requestOptions: RequestInit = {
    method,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  if (method !== HTTP_METHOD.GET) {
    requestOptions = {...requestOptions, body: JSON.stringify(body)};
  }

  return fetch(
    `https://photo-locker-backend.onrender.com${url}`,
    requestOptions,
  )
    .then(handleResponse)
    .then(response => {
      return response;
    });
};
