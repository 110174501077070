import {createApp} from 'vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import App from './App.vue';
import './registerServiceWorker';
import './css/_theme.scss';
import router from './router';
import store from './store';

import AOS from 'aos';
import 'aos/dist/aos.css';

/* import the fontawesome core */
import {library} from '@fortawesome/fontawesome-svg-core';

/* import font awesome icon component */
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

/* import specific icons */
import {fas} from '@fortawesome/free-solid-svg-icons';
import {fab} from '@fortawesome/free-brands-svg-icons';

/* add icons to the library */
library.add(fas, fab);

AOS.init({
  duration: 1200,
  once: false,
});

createApp(App)
  .use(store)
  .use(router)
  .component('font-awesome-icon', FontAwesomeIcon)
  .mount('#app');
