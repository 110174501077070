import {createStore} from 'vuex';

export default createStore({
  state: {
    isDarkMode: false,
    loadedScreens: [] as string[],
    emailSent: false,
  },
  getters: {
    EmailSent(state) {
      return state.emailSent;
    },
  },
  mutations: {
    addLoadedScreen(state, screen: string) {
      state.loadedScreens.push(screen);
    },
    setEmailSent(state, data: boolean) {
      state.emailSent = data;
    },
  },
  actions: {
    setIsDarkMode(context, data: boolean) {
      context.state.isDarkMode = data;
    },
    setEmailSent(context, data: boolean) {
      context.commit('setEmailSent', data);
    },
  },
  modules: {},
});
