type IProject = {
  title: string;
  description: string;
  link: string;
  linkTwo?: string;
  techStack: string[];
  imageSource: string;
  showImageRight: boolean;
  isSmallImage?: boolean;
  isLargeImage?: boolean;
}[];

const techStack = {
  React: 'React',
  ReactNative: 'React Native',
  Next: 'Next.js',
  Vue: 'Vue',
  TypeScript: 'TypeScript',
  PostgreSQL: 'PostgreSQL',
  Firebase: 'Firebase',
  AWS: 'AWS',
  Node: 'Node.js',
  python: 'Python',
  Django: 'Django',
  mobx: 'MobX',
  zustand: 'Zustand',
  CSharp: 'C#',
  admob: 'AdMob',
};

export const projects: IProject = [
  {
    title: 'Brain Twist: Picture Puzzle',
    description:
      'I developed Brain Twist: Picture Puzzle, a mobile app where users guess words from creative images. Utilising React Native, I built an engaging and accessible gameplay experience, along with a backend to manage user data and stats.',
    link: 'https://braintwist.quormotion.com',
    techStack: [
      techStack.ReactNative,
      techStack.Vue,
      techStack.TypeScript,
      techStack.mobx,
      techStack.Node,
      techStack.PostgreSQL,
      techStack.AWS,
      techStack.Firebase,
      techStack.admob,
    ],
    imageSource: 'brain-twist',
    showImageRight: true,
  },
  {
    title: 'Play Today. App',
    description:
      'I led the development of the scoring system for the Golf app using React-Native. I also built the backend to manage user data and game statistics and created a website to showcase the app.',
    link: 'https://app.playtoday.cc',
    techStack: [
      techStack.ReactNative,
      techStack.React,
      techStack.TypeScript,
      techStack.mobx,
      techStack.Node,
      techStack.PostgreSQL,
      techStack.AWS,
      techStack.Firebase,
    ],
    imageSource: 'playtoday-app',
    showImageRight: false,
  },
  {
    title: 'Play Today. Marketplace',
    description:
      'I developed the entire project, optimising performance for loading NFTs and handling the full purchase process.',
    link: 'https://marketplace.playtoday.cc',
    techStack: [
      techStack.React,
      techStack.Next,
      techStack.TypeScript,
      techStack.PostgreSQL,
      techStack.Firebase,
      techStack.zustand,
    ],
    imageSource: 'playtoday-marketplace',
    showImageRight: true,
  },
  {
    title: 'Play Today. Metaverse',
    description:
      'Led the development of the React front end to initialise a Unity instance for rendering WebGL projects, manage user authentication, and facilitate data transfer to the WebGL build. Also contributed to the Unity side with C# to dynamically manage server interactions.',
    link: 'https://playtoday.cc',
    techStack: [
      techStack.CSharp,
      techStack.React,
      techStack.TypeScript,
      techStack.Firebase,
      techStack.zustand,
    ],
    imageSource: 'playtoday-metaverse',
    showImageRight: false,
    isSmallImage: true,
  },
];

export const personalProjects: IProject = [
  {
    title: 'Pet Entries',
    description:
      'A cross-platform app using React Native and Node.js to solve a real-world problem by simplifying animal record-keeping. Implemented code modularity and consistency with TypeScript. Integrated MobX for real-time updates. Utilised PostgreSQL and AWS services for data storage and hosting. Designed UI/UX for optimal performance and user experience.',
    // link: 'https://petentries.com',
    link: 'https://gitlab.com/Mohammad260/pet-entries.git',
    linkTwo: 'https://gitlab.com/Mohammad260/pet-entries-backend.git',
    techStack: [
      techStack.ReactNative,
      techStack.TypeScript,
      techStack.mobx,
      techStack.Node,
      techStack.PostgreSQL,
      techStack.AWS,
    ],
    imageSource: 'petentries',
    showImageRight: true,
    isLargeImage: true,
  },
  {
    title: 'Snap Locker',
    description:
      'A multi-platform app using React Native and Node.js for secure photo storage. Utilised S3 for authenticated image storage. Future plans include implementing photo editing, filtering, and AI features such as background replacement, retouching, quality enhancement, and image generation.',
    link: 'https://snaplocker.quormotion.com',
    techStack: [
      techStack.ReactNative,
      techStack.TypeScript,
      techStack.mobx,
      techStack.Node,
      techStack.PostgreSQL,
      techStack.AWS,
    ],
    imageSource: 'snaplocker',
    showImageRight: false,
    isLargeImage: true,
  },
];
