import {httpService, HTTP_METHOD} from './httpUtil';

export interface IPortfolioContact {
  id?: number;
  subject: string;
  email: string;
  message: string;
  created_at?: string;
}

export const contact = (
  data: IPortfolioContact,
): Promise<IPortfolioContact> => {
  return httpService({
    method: HTTP_METHOD.POST,
    url: '/contact/portfolio',
    body: data,
  });
};
