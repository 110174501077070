<template>
  <div class="d-md-flex justify-content-center flex-wrap mt-4">
    <div class="d-flex flex-column align-items-center">
      <router-link
        to="/contact"
        class="btn btn-lg grow-on-hover contact-link rounded-pill text-center mx-2 my-1">
        Contact me here
        <font-awesome-icon
          :icon="['fas', 'arrow-right']"
          size="sm"
          class="icon" />
      </router-link>
    </div>

    <div class="d-flex flex-column align-items-center mb-3">
      <a
        href="Mohammad Rezaul Karim - Resume.pdf"
        class="btn btn-secondary btn-lg grow-on-hover rounded-pill text-center mx-2 my-1"
        download>
        Download CV
        <font-awesome-icon :icon="['fas', 'download']" size="sm" class="icon" />
      </a>
    </div>

    <div class="d-flex flex-column align-items-center mb-3">
      <a
        href="https://www.linkedin.com/in/mohammad-karim-331559164/"
        target="_blank"
        class="btn btn-secondary btn-lg grow-on-hover rounded-circle text-center mx-2 my-1">
        <font-awesome-icon icon="fab fa-linkedin" size="sm" class="icon" />
      </a>
    </div>

    <div class="d-flex flex-column align-items-center mb-3">
      <a
        href="https://gitlab.com/Mohammad260"
        target="_blank"
        class="btn btn-secondary btn-lg grow-on-hover rounded-circle text-center mx-2 my-1">
        <font-awesome-icon icon="fab fa-gitlab" size="sm" class="icon" />
      </a>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
.grow-on-hover {
  transition: transform 0.2s ease-in-out;
}

.grow-on-hover:hover {
  transform: scale(1.1);
}

.contact-link {
  color: white;
  background-color: #0a0b0c;
}
</style>
